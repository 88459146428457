import React, { useState } from "react";
import { IoBagAddOutline } from "react-icons/io5";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { Link } from "react-router-dom";

const GroceriesCard = ({ productItems, addToCart }) => {
  const [count, setCount] = useState(0);
  const increment = () => {
    setCount(count + 1);
  };
  return (
    <div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3">
        {productItems.map((product, index) => {
          // mapping throught the array of data and using objects in the array to use in the page
          // Calculate the discounted price
          const discountedPrice = (
            product.price -
            (product.price * product.discount) / 100
          ).toFixed(2);

          return (
            <div
              className="rounded-lg overflow-hidden transition-all duration-300 transform hover:scale-105 px-2"
              key={index}
            >
              <div className="product mtop">
                <div className="img">
                  <span className="discount">{product.discount}% Off</span>
                  <img src={product.img} style={{ height: 230 }} alt="" />
                  <div className="product-like">
                    <label>{count}</label> <br />
                    <i className="fa-regular fa-heart" onClick={increment}></i>
                  </div>
                </div>
                <div className="">
                  <Link to={`/all-products/${product.id}`}>
                    <h3 className="truncate">{product.name}</h3>
                  </Link>
                  <div className="flex items-center my-2 text-yellow-500">
                    <MdOutlineStarPurple500 color="gold" />
                    <MdOutlineStarPurple500 color="gold" />
                    <MdOutlineStarPurple500 color="gold" />
                    <MdOutlineStarPurple500 color="gold" />
                    <MdOutlineStarPurple500 color="gold" />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="text-gray-500 line-through">
                        Rs. {product.price}.00
                      </h4>
                      <h4 className="text-green-600 text-lg font-bold">
                        Rs. {discountedPrice}
                      </h4>
                    </div>
                    <button
                      aria-label="Add to cart"
                      className="!bg-green-500 hover:bg-green-600 !text-white px-1 !rounded-full !shadow-md !transition-colors"
                      onClick={() => addToCart(product)}
                    >
                      <IoBagAddOutline size={25} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroceriesCard;
