import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Mycat from "../../Mycat/Mycat";
import homeCareCleaningData from "./homeCareCleaningData";
import HomeCareCleaningCard from "./HomeCareCleaningCard";

const HomeCareCleaning = ({ productItems, addToCart, cartItems }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await homeCareCleaningData();
      setProducts(data);
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Header cartItems={cartItems} />
      <Mycat />
      <h2 className="text-center font-bold text-gray-700 lg:text-xl pb-6">
        Home Care & Cleaning
      </h2>
      <HomeCareCleaningCard productItems={products} addToCart={addToCart} />
    </>
  );
};

export default HomeCareCleaning;
