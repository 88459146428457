// src/components/Loginform/Loginform.jsx
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import toast from "react-hot-toast";
import "./loginform.css";

const Loginform = () => {
  const { login } = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    const { username, password } = event.target.elements;

    try {
      console.log("Attempting login with username:", username.value);
      await login(username.value, password.value);
      toast.success("Logged in successfully!");
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed. Please check your credentials.");
    }
  };

  return (
    <section className="w-full flex justify-center items-center">
      <div className="max-w-screen-md bg-slate-500 bg-opacity-35 rounded-md mx-6 mb-6 shadow-blue-300 shadow-lg">
        <div className="wrapper lg:p-12 md:p-12 p-3 text-sm">
          <form onSubmit={handleLogin} className="leading-10">
            <label className="w-full flex justify-start pl-2">Username</label>
            <div>
              <input
                type="text"
                name="username"
                className="!m-0 !w-[95%] !lg:w-full"
                required
              />
            </div>
            <label className="w-full flex justify-start pl-2">Password</label>
            <div className="">
              <input
                type="password"
                name="password"
                className="!m-0 !w-[95%] !lg:w-full"
                required
              />
            </div>
            {/* <p className="">
              Forgot Password ?{" "}
              <span>
                <Link to="/forgot-password">Click here to reset</Link>
              </span>
            </p> */}
            <button className="!m-2 !my-5 !bg-blue-500 text-white lg:text-lg text-sm w-[95%] py-2 rounded-md">
              Sign In
            </button>
          </form>
          <div className="my-2">
            {/* <h1 className="text-6xl text-red">Sign In</h1> */}
            <p>
              New User ?{" "}
              <span>
                <Link to="/register" className="!text-green-600 !font-semibold">
                  Create an account
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Loginform;
