import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Mycat from "../../Mycat/Mycat";
import leatherGoodsData from "./leatherGoodsData";
import LeatherGoodsCard from "./LeatherGoodsCard";

const LeatherGoods = ({ productItems, addToCart, cartItems }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await leatherGoodsData();
      setProducts(data);
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Header cartItems={cartItems} />
      <Mycat />
      <h2 className="text-center font-bold text-gray-700 lg:text-xl pb-6">
        Leather Goods
      </h2>
      <LeatherGoodsCard productItems={products} addToCart={addToCart} />
    </>
  );
};

export default LeatherGoods;
