import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Mycat from "../../Mycat/Mycat";
import groceriesData from "./groceriesData";
import GroceriesCard from "./GroceriesCard";

const Groceries = ({ productItems, addToCart, cartItems }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await groceriesData();
      setProducts(data);
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Header cartItems={cartItems} />
      <Mycat />
      <h2 className="text-center font-bold text-gray-700 lg:text-xl pb-6">
        Groceries
      </h2>
      <GroceriesCard productItems={products} addToCart={addToCart} />
    </>
  );
};

export default Groceries;
