import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC6-WLZqqUFdlD_2lQODlUso0l3hhLjwE0",
  authDomain: "voyger-29b0b.firebaseapp.com",
  projectId: "voyger-29b0b",
  storageBucket: "voyger-29b0b.appspot.com",
  messagingSenderId: "707485455146",
  appId: "1:707485455146:web:d26ee31ce8b0cdf94d8453",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
