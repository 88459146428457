// const allProductsData = {
//   allProductsData: [
//     {
//       id: 1,
//       discount: product.discount,
//       img: "/assets/flash-deals/flash-1.png",
//       name: "Shoessss",
//       price: 100,
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 2,
//       discount: 40,
//       img: "/assets/flash-deals/flash-2.png",
//       name: "Watch",
//       price: 20,
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 3,
//       discount: 40,
//       img: "/assets/flash-deals/flash-3.png",
//       name: "Smart Mobile Black",
//       price: 200,
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 4,
//       discount: 40,
//       img: "/assets/flash-deals/flash-4.png",
//       name: "Smart Watch Black",
//       price: 50,
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 5,
//       discount: product.discount,
//       img: "/assets/flash-deals/flash-5.png",
//       name: "Glasses",
//       price: 100,
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 6,
//       discount: product.discount,
//       img: "/assets/flash-deals/flash-5.png",
//       name: "Smart Glasses",
//       price: 100,
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 7,
//       img: "/assets/shop-items/shops-1.png",
//       name: "Mapple Earphones",
//       price: "180",
//       discount: "25",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 8,
//       img: "/assets/shop-items/shops-2.png",
//       name: "Vivo android one",
//       price: "120",
//       discount: "10",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 9,
//       img: "/assets/shop-items/shops-3.png",
//       name: "Sony Light",
//       price: "20",
//       discount: "50 ",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 10,
//       img: "/assets/shop-items/shops-4.png",
//       name: "Iphone",
//       price: "999",
//       discount: "10 ",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 11,
//       img: "/assets/shop-items/shops-5.png",
//       name: "Ceats wireless earphone",
//       price: "80",
//       discount: "20 ",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 12,
//       img: "/assets/shop-items/shops-7.png",
//       name: "Redimi Phone",
//       price: "400",
//       discount: "20 ",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 13,
//       img: "/assets/shop-items/shops-8.png",
//       name: "Xeats Bluetooth earphones",
//       price: "60",
//       discount: "5 ",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 14,
//       img: "/assets/shop-items/shops-9.png",
//       name: "Airpod",
//       price: "120",
//       discount: "10",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//     {
//       id: 15,
//       img: "/assets/shop-items/shops-10.png",
//       name: "Silver Cap",
//       price: "5",
//       discount: "2",
//       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam suscipit, neque vitae egestas porta, eros neque vestibulum purus, at pretium nulla tellus sed mauris. Aliquam nec lacus non metus bibendum faucibus. Etiam vulputate leo nec ante consectetur, sed vulputate dui vestibulum. Quisque porta augue sapien, et lobortis odio molestie mattis. Aliquam erat volutpat. Morbi semper lacus et augue aliquam pretium. Sed interdum risus eu sem gravida malesuada. Suspendisse efficitur elementum ex, sed varius tortor auctor eu. Ut interdum posuere lacus, sed tristique erat blandit et. Nulla ac odio ullamcorper, vestibulum urna sed, tristique turpis. Pellentesque maximus porttitor molestie. Nunc consequat.",
//     },
//   ],
// };

// export default allProductsData;

// Import axios for making HTTP requests
import axios from "axios";

// Define the API endpoint
const apiEndpoint = "https://voyger.online/voyger/all_products.php/";

// Function to fetch data from the API
const fetchDataFromAPI = async () => {
  try {
    const response = await axios.get(apiEndpoint);
    return response.data; // Assuming your API returns an array of products like the example you provided
  } catch (error) {
    console.error("Error fetching data from API:", error);
    return []; // Return an empty array in case of an error
  }
};

// Export the fetched data directly
const allProductsData = async () => {
  const fetchedData = await fetchDataFromAPI(); // Fetch data from the API
  if (fetchedData.length > 0) {
    return fetchedData.map((product) => ({
      id: product.p_id,
      discount: product.discount, // Set discount as needed
      img: `https://voyger.online/voyger/assets/img/${product.img}`, // Set image path as needed
      name: product.p_name,
      price: parseFloat(product.price), // Parse price to float
      desc: product.benefits, // Use benefits as description or customize as needed
    }));
  } else {
    console.error("No data fetched from API.");
    return []; // Return an empty array if no data is fetched
  }
};

// Export allProductsData directly
export default allProductsData();
