import React from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../contexts/AuthContext";

const Registrationform = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleRegister = async (event) => {
    event.preventDefault();
    const { username, password, mobile } = event.target.elements;

    try {
      const response = await fetch(
        "https://voyger.online/voyger/register.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: username.value,
            password: password.value,
            mobile: mobile.value,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          // Automatically log the user in
          await login(username.value, password.value);
          toast.success("Registered and logged in successfully!");
          navigate("/");
        } else {
          toast.error(data.message || "Registration failed");
        }
      } else {
        toast.error("Registration failed");
      }
    } catch (error) {
      console.error("Error registering user:", error);
      toast.error("Registration failed");
    }
  };

  return (
    <section className="w-full flex justify-center items-center my-2">
      <div className="!md:max-w-[60%] !lg:max-w-full bg-slate-500 bg-opacity-35 rounded-md mx-6 mb-6 shadow-green-300 shadow-lg">
        <div className="wrapper lg:p-12 md:p-12 p-3 text-sm">
          <form onSubmit={handleRegister} className="leading-10">
            <label className="w-full flex justify-start pl-2">Username</label>
            <div>
              <input
                type="text"
                name="username"
                required
                className="!m-0 !w-[95%] !lg:w-full"
              />
            </div>
            <label className="w-full flex justify-start pl-2">Password</label>
            <div>
              <input
                type="password"
                name="password"
                required
                className="!m-0 !w-[95%] !lg:w-full"
              />
            </div>
            <label className="w-full flex justify-start pl-2">
              Mobile Number
            </label>
            <div>
              <input
                type="text"
                name="mobile"
                required
                className="!m-0 !w-[95%] !lg:w-full"
              />
            </div>
            <button className="!m-2 !my-5 !bg-green-500 !hover:bg-green-800 text-white lg:text-lg text-sm w-[95%] py-2 rounded-md">
              Sign Up
            </button>
          </form>
          <div className="my-2">
            {/* <h1 className="text-6xl text-red">Sign Up</h1> */}
            <p>
              Already have an account?{" "}
              <span>
                <Link to="/login" className="!text-blue-600 !font-semibold">
                  Sign In
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registrationform;
