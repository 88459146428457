import React from "react";
import { useParams } from "react-router-dom";

const Singleproduct = ({ allProductsData, addToCart }) => {
  let id = useParams();
  return (
    <>
      {allProductsData.map((product, index) => {
        if (product.id === id.id) {
          // Calculate the discounted price
          const discountedPrice = (
            product.price -
            (product.price * product.discount) / 100
          ).toFixed(2);

          return (
            <div key={index} className="p-4 max-w-sm mx-auto">
              <section className="bg-white shadow-lg rounded-lg overflow-hidden">
                {/* Product Name */}
                <div className="p-4 text-center text-xl font-semibold text-gray-800">
                  {product.name}
                </div>

                {/* Product Image and Price */}
                <div className="relative">
                  <img
                    src={product.img}
                    alt={product.name}
                    className="w-full object-cover"
                  />
                  <div className="absolute bottom-2 right-2 bg-green-500 text-white px-4 py-1 rounded-full text-sm">
                    <div>
                      <h4 className="text-white line-through">
                        Rs. {product.price}.00
                      </h4>
                      <h4 className="text-white text-lg font-bold">
                        Rs. {discountedPrice}
                      </h4>
                    </div>
                  </div>
                </div>

                {/* Product Description and Discount */}
                <div className="p-4">
                  <p className="text-gray-700 text-sm mb-2">{product.desc}</p>
                  {/* <div className="text-red-500 font-medium">
                    Currently it's {product.discount}% Off but not for long!
                  </div> */}
                </div>

                {/* Add to Cart Button */}
                <div className="p-4">
                  <button
                    aria-label="Add to cart"
                    className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
                    onClick={() => addToCart(product)}
                  >
                    Add To Cart
                  </button>
                </div>
              </section>
            </div>
          );
        }
      })}
    </>
  );
};
export default Singleproduct;
