import React from "react";
import "./myCat.css";
// import { Link } from "react-router-dom";
import CatSlider from "./CatSlider";

const Mycat = () => {
  return (
    <>
      <CatSlider />
    </>
  );
};

export default Mycat;
