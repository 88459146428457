import React from "react";
import Slider from "react-slick";
import "./myCat.css";
import { Link } from "react-router-dom";

export default function CatSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024, // large screens
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // medium screens like tablets
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // small screens like mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider
      {...settings}
      className="mb-10 mx-4 text-xs md:text-base lg:text-base"
    >
      {" "}
      {/* Add some outer padding for the slider */}
      <div className="bg-cat1 px-6">
        {" "}
        {/* Add padding and margin to create space between slides */}
        <Link className="bg-cat1" to={"/fashion-and-lifestyle"}>
          <div className="my-text-white my-center">
            <div>
              <p>Fashion & Lifestyle</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="bg-cat2 px-6">
        <Link to={"/herbal"} className="">
          <div className="my-text-white my-center">
            <p>Herbal</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat3 px-6">
        <Link to={"/health"}>
          <div className="my-text-white my-center">
            <p>Health</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat4 px-6">
        <Link to={"/groceries"}>
          <div className="my-text-white my-center">
            <div>
              <p>Groceries</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="bg-cat5 px-6">
        <Link to={"/wellness"}>
          <div className="my-text-white my-center">
            <p>Wellness</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat6 px-6">
        <Link to={"/personal-care"}>
          <div className="my-text-white my-center">
            <p>Personal Care</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat7 px-6">
        <Link to={"/leather-goods"}>
          <div className="my-text-white my-center">
            <p>Leather Goods</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat8 px-6">
        <Link to={"/home-care-cleaning"}>
          <div className="my-text-white my-center">
            <p>Home care and Cleaning</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat9 px-6">
        <Link to={"/home-appliances"}>
          <div className="my-text-white my-center">
            <p>Home appliances</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat10 px-6">
        <Link to={"/fitness-equipments"}>
          <div className="my-text-white my-center">
            <p>Fitness equipments</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat11 px-6">
        <Link to={"/utility-items"}>
          <div className="my-text-white my-center">
            <p>Utility Items</p>
          </div>
        </Link>
      </div>
      <div className="bg-cat12 px-6">
        <Link to={"/daily-life"}>
          <div className="my-text-white my-center">
            <p>Daily Life Use</p>
          </div>
        </Link>
      </div>
    </Slider>
  );
}
