import React, { useEffect, useState } from "react";
import fashionAndLifestyleData from "./fashionAndLifestyleData";
import FashionAndLifestyleCard from "./FashionAndLifestyleCard";
import Header from "../../Header/Header";
import Mycat from "../../Mycat/Mycat";
import Footer from "../../Footer/Footer";

const FashionAndLifestyle = ({ productItems, addToCart, cartItems }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await fashionAndLifestyleData();
      setProducts(data);
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Header cartItems={cartItems} />
      <Mycat />
      <h2 className="text-center font-bold text-gray-700 lg:text-xl pb-6">
        Fashion & Lifestyles
      </h2>
      <FashionAndLifestyleCard productItems={products} addToCart={addToCart} />
      <Footer />
    </>
  );
};

export default FashionAndLifestyle;
