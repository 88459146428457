import React from "react";
import { CiPhone } from "react-icons/ci";
import { IoMdMail } from "react-icons/io";

export const Head = () => {
  return (
    <>
      <section className="hidden lg:block">
        <div className="lg:flex lg:justify-between bg-[#0f3460] text-white p-4 sm:hidden lg:px-12">
          <div className="flex items-center gap-7">
            <div className="flex gap-2 items-center">
              <CiPhone size={20} />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=9875437382"
                className="text-sm"
              >
                {/* <i className="fa fa-phone"></i> */}
                033-24381060
              </a>
            </div>
            <div className="flex gap-2 items-center">
              <IoMdMail size={20} />
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:info@voyger.com"
                className="text-sm"
              >
                info@voyger.co.in
              </a>
            </div>
          </div>
          <div className="flex gap-3 text-sm">
            <span>
              <label htmlFor="">Need Help ?</label>
            </span>
            <span>
              <span>🇧🇩 </span>
              <label htmlFor="">EN</label>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
