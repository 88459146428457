import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Mycat from "../../Mycat/Mycat";
import homeAppliancesData from "./homeAppliancesData";
import HomeAppliancesCard from "./HomeAppliancesCard";

const HomeAppliances = ({ productItems, addToCart, cartItems }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await homeAppliancesData();
      setProducts(data);
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Header cartItems={cartItems} />
      <Mycat />
      <h2 className="text-center font-bold text-gray-700 lg:text-xl pb-6">
        Home Appliances
      </h2>
      <HomeAppliancesCard productItems={products} addToCart={addToCart} />
    </>
  );
};

export default HomeAppliances;
